/* eslint-disable @typescript-eslint/no-explicit-any */
import { Layout } from 'layout/Layout'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SideFilter } from 'components/filters'
import { LocalizedLink } from '../i18n/LocalizedLink'
import { getImageByImageName } from 'utils/getImageByImageName'
import Img, { FluidObject } from 'gatsby-image'
import { imagesDataProp } from 'types/interfaces'
import { searchStringToObject } from 'use-query-params'
import { graphql } from 'gatsby'
import { colors } from 'styles/colors'
import { AvailableFiltersListCard } from 'components/filters/AvailableFiltersList'
import { Button } from 'components/Button'
import { displayWidth } from 'styles/width'
import { SideModal } from 'components/Modal'

const LocalizedLinkS = styled(LocalizedLink)`
    text-decoration: none;
`

const Wrapper = styled.div`
    flex-direction: column;
    position: relative;
    display: flex;
    max-height: calc(100vh - 126px);
    width: 100vw;
    overflow: hidden;
    @media (min-width: ${displayWidth.tablet}) {
        flex-direction: row;
        max-height: calc(100vh - 90px);
    }
`
const Count = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ccc;
    padding: 4px 8px;
    font-size: 12px;
`
const ScrollBlock = styled.div`
    overflow-y: auto;
    width: 100%;
    border-top: 1px solid #ccc;
`
const FilterBlock = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        flex-shrink: 0;
        overflow-y: auto;
    }
`
const FilterButton = styled(Button)`
    width: 200px;
    max-height: 28px;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    @media (min-width: ${displayWidth.tablet}) {
        flex-direction: row;
    }
`

const Price = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 25px;
    text-transform: capitalize;
    color: ${colors.accentText};
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    color: ${colors.dark};
    margin: 10px;
    padding-bottom: 30px;
    h3 {
        font-size: 27px;
        text-align: center;
    }
`

const Image = styled(Img)<{ fluid: FluidObject }>`
    width: 100%;
    height: auto;
    color: transparent;
    margin: 5px;
`
const ImageWrapper = styled.div`
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    @media (min-width: ${displayWidth.tablet}) {
        width: 40%;
        min-height: 350px;
        display: flex;
        align-items: center;
    }
`
const ImageWrapper2 = styled(ImageWrapper)`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: flex;
    }
`
const Empty = styled.div`
    height: calc(100vh - 100px);
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font-size: 20px;
        padding: 16px;
    }
`
export interface ProjectImages {
    relativeDirectory: string
    childImageSharp: {
        fluid: FluidObject
        parent: { name: string }
        id: string
    }
}

const Houses = ({
    data,
    location,
}: {
    data: {
        allHousesYaml: {
            edges: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                node: any
            }[]
        }
        allImageSharp: imagesDataProp
    }
    location: { search: string; state: { prevPath: string } }
}) => {
    const { i18n, t } = useTranslation()

    const filters = searchStringToObject(location.search)
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false)
    const [filteredHouses, setFilteredHouses] = useState<
        {
            node: any
        }[]
    >([])

    const filtersKeys = Object.keys(filters)

    const callback = useCallback(
        () =>
            setFilteredHouses(() =>
                data.allHousesYaml.edges.filter(({ node }) => {
                    const filteredNodeFilters = { ...node.filters }
                    delete filteredNodeFilters['houseArea']
                    delete filteredNodeFilters['garageType']

                    return filtersKeys.every((key) => {
                        return (
                            node.filters[key] !== undefined &&
                            (key == 'houseArea' ||
                                key == 'garageType' ||
                                filteredNodeFilters[key] == filters[key]) &&
                            (filters['houseArea']?.length
                                ? Number(filters['houseArea'][0]) <=
                                      Number(node.filters['houseArea']) &&
                                  Number(filters['houseArea'][1]) >=
                                      Number(node.filters['houseArea'])
                                : true) &&
                            (filters['garageType']
                                ? node.filters['garageType'] != 'no'
                                : true)
                        )
                    })
                })
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.search]
    )

    useEffect(() => {
        const timeout = setTimeout(callback, 500)
        return () => clearTimeout(timeout)
    }, [callback, location.search])

    useEffect(() => {
        setTimeout(
            () =>
                window?.previousPath &&
                filteredHouses.length &&
                window!.document!.getElementById(window?.previousPath) &&
                window!
                    .document!.getElementById(window?.previousPath)
                    .scrollIntoView({
                        block: 'start',
                        behavior: 'auto',
                    }),
            400
        )
    }, [filteredHouses.length])

    return (
        <Layout noFooter={false} hideCallbackProp id="houses">
            <Helmet>
                <title>Clearline-Houses</title>
                <meta name="description" content={'lala'} />
            </Helmet>
            <Wrapper>
                <FilterButton onClick={() => setIsFiltersOpen(true)}>
                    {t('houses.filters')}{' '}
                    {!!filtersKeys.length && `(${filtersKeys.length})`}
                </FilterButton>
                <FilterBlock>
                    <SideFilter location={location.search} />
                </FilterBlock>
                <ScrollBlock>
                    {filteredHouses.length ? (
                        filteredHouses.map((node: any, i: React.Key) => {
                            const item = node.node[i18n.language]
                            const imageFluid = getImageByImageName(
                                data.allImageSharp,
                                node.node.previewImage[0]
                            )
                            const imageFluid2 = getImageByImageName(
                                data.allImageSharp,
                                node.node.previewImage[1]
                            )
                            const image0 =
                                node.node.image0 &&
                                getImageByImageName(
                                    data.allImageSharp,
                                    node.node.image0
                                )

                            return (
                                <LocalizedLinkS
                                    key={i}
                                    state={{ prevPath: 'houses' }}
                                    to={`/house/${node.node.parent.name}`}
                                >
                                    <Card id={node.node.parent.name}>
                                        <ImageWrapper>
                                            <Image
                                                fluid={
                                                    image0
                                                        ? image0.fluid
                                                        : imageFluid.fluid
                                                }
                                                loading="eager"
                                                alt={item.alt[0]}
                                                title={item.alt[0]}
                                            />
                                        </ImageWrapper>
                                        <ImageWrapper2>
                                            <Image
                                                fluid={imageFluid2.fluid}
                                                loading="eager"
                                                alt={item.alt[1]}
                                                title={item.alt[1]}
                                            />
                                        </ImageWrapper2>
                                        <Info>
                                            <AvailableFiltersListCard
                                                filters={node.node.filters}
                                            />
                                        </Info>
                                        <Price>{t('price')} - 2000 ₴</Price>
                                    </Card>
                                </LocalizedLinkS>
                            )
                        })
                    ) : (
                        <Empty>
                            <p>{t('houses.no_result')}</p>
                        </Empty>
                    )}
                </ScrollBlock>
                {!!filteredHouses.length && (
                    <Count>
                        {t('houses.available') + filteredHouses.length}
                    </Count>
                )}
                <SideModal
                    isOpen={isFiltersOpen}
                    closeHandler={() => setIsFiltersOpen(false)}
                >
                    <SideFilter
                        location={location.search}
                        closeHandler={() => setIsFiltersOpen(false)}
                    />
                </SideModal>
            </Wrapper>
        </Layout>
    )
}

export default Houses

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [800]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }

        allHousesYaml(sort: { fields: filters___houseArea, order: ASC }) {
            edges {
                node {
                    parent {
                        ... on File {
                            name
                        }
                    }
                    id
                    en {
                        description
                        title
                        alt
                    }

                    previewImage
                    ru {
                        description
                        title
                        alt
                    }
                    uk {
                        description
                        title
                        alt
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                    filters {
                        houseArea
                        houseWidth
                        houseHeight
                        ceilingHeight

                        project
                        type
                        floor

                        kitchen
                        living
                        dining
                        wardrobe
                        laundry
                        kitchenAndLiving
                        livingAndDining
                        kitchenAndDining
                        kitchenAndLivingAndDining
                        mainBathroom
                        guestBathroom
                        bedroom
                        bedroomAndBathroom
                        childrens
                        childrensAndBathroom
                        childrens2
                        childrens2AndBathroom
                        guest
                        office
                        veranda
                        garageType
                        carsCount
                        basement
                        mansard
                        hallway
                        hall
                        hallwayAndWardrobe
                        boilerOrTechnical
                    }
                }
            }
        }
    }
`
